<template>
    <div id="ChartAccountGroup">
        <v-container fluid app>
            <ZnapTable ref="znapTable"
                :endpoint="endpoint"
                :options="options"
                :tableRows="tableRows"
                :noData="noData"
                @setNoData="setNoData"
                :noDataMessage="noDataMessage"
                @setNoDataMessage="setNoDataMessage"
                :filterConditions="filterConditions"
                @setFilterConditions="setFilterConditions"
                @clearFilters="setFilterConditions"
                :loadTable="loadTable"
                @setLoadTable="setLoadTable"
                @clearNotRequiredOptionsItems="clearNotRequiredOptionsItems"
            >
                <!-- // Filters -->
                <template v-slot:filters>
                    <ZnapFilters ref="znapFilters"
                        :filterComponents="options"
                        :noData="noData"
                        @setSelection="populateFilterConditions"
                        :clearFilters="clearFilters"
                        @setClearFilters="setClearFilters"
                    >
                    </ZnapFilters>
                </template>
            </ZnapTable>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapTable from '@/components/znap/ZnapTable.vue'
import ZnapFilters from '@/components/znap/Filters.vue'

export default {
    name:'ChartAccountGroup',

    components: { ZnapTable, ZnapFilters },

    computed: {
        endpoint() {
            return [
                this.$ipAccount,
                'chart-account-group',
                {
                    conditions: []
                }
            ]
        },
    },

    data: () => ({
        tableRows: 10000,

        options: [
            {
                column: 'id_chart_account_group_version',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Versão das contas gerenciais X Contas contábeis',
                required: true,
                filterable: false,
                endpoint: [ Vue.prototype.$ipAccount, 'chart-account-group-version' ],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_module',
                        operator: '=',
                        value: 14
                    }
                ],
                filters: ['id_chart_account_version', 'id_account_group_version']
            },
            {
                column: 'id_chart_account_version',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Versão do plano de contas contábil',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'chart-account-version' ],
                filters: ['id_chart_account'],
                filteredBy: ['id_chart_account_group_version'],
                filteredItems: []
            },
            {
                column: 'id_chart_account',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Conta contábil',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'chart-account' ],
                filteredBy: ['id_chart_account_version'],
                filteredItems: []
            },
            {
                column: 'id_account_group_version',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Versão das contas gerenciais',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipAccount, 'account-group-version' ],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_module',
                        operator: '=',
                        value: 14
                    }
                ],
                filters: ['id_account_group'],
                filteredBy: ['id_chart_account_group_version'],
                filteredItems: []
            },
            {
                column: 'id_account_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Conta gerencial',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipAccount, 'account-group' ],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_module',
                        operator: '=',
                        value: 14
                    }
                ],
                filteredBy: ['id_account_group_version'],
                filteredItems: []
            }
        ],

        noDataMessage: '',
        noData: true,

        // Filters
        filterConditions: [],
        loadTable: false,
        clearFilters: false,
    }),

    methods: {
        populateFilterConditions(selectionArray, requiredFilter) {
            this.filterConditions = []

            if (requiredFilter) {
                this.filterConditions.push({
                    AndOr: 'AND',
                    column: selectionArray[0].column,
                    operator: '=',
                    value: selectionArray[0].id,
                    required: true
                })

                this.loadTable = true
            } else {
                if (selectionArray.length !== 0) {
                    selectionArray.forEach(selection => {
                        
                        let required = false
                        this.options.forEach(option => {    
                            if (option.required && selection.column === option.column) {
                                required = true
                            }
                        })

                        if (selection.id === undefined) {
                            this.filterConditions = []
                        } else {
                            if (selection.id.length > 1) {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: 'IN',
                                    value: selection.id,
                                    required
                                })
                            } else {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: '=',
                                    value: selection.id,
                                    required
                                })
                            }             
                        }
                    })
                }
            }
        },

        setNoDataMessage(message) {
            this.noDataMessage = message
        },

        setNoData(payload) {
            this.noData = payload
        },

        setFilterConditions(array) {
            this.filterConditions = array

            if (array.length === 0) {
                this.clearFilters = true
            }

            this.options.forEach(option => {
                if (option.required) {
                    this.loadTable = true
                } 
            })
        },

        setClearFilters(payload) {
            this.clearFilters = payload
        },

        clearNotRequiredOptionsItems() {
            this.options.forEach(option => {
                if (!option.required) {
                    option.items = []
                } 
            })
        },

        setLoadTable(payload) {
            this.loadTable = payload
        }
    },
}
</script>

<style>
</style>